import { ACCESS } from '@/utils/constants/casl'

export default {
  path: 'dashboard',
  name: 'dashboard',
  meta: {
    title: 'Dashboard',
    action: 'read',
    subject: ACCESS.PUBLIC
  },
  component: () => import('./DashboardPage.vue')
}
