import { ACCESS } from '@/utils/constants/casl'

export default [
  {
    path: 'role',
    name: 'admin.role',
    meta: {
      title: 'Admin Role List',
      action: 'read',
      subject: ACCESS.ROLE
    },
    component: () => import('./list/AdminRoleListPage.vue')
  },
  {
    path: 'role/add',
    name: 'admin.role.add',
    meta: {
      title: 'Admin Role Add',
      action: 'create',
      subject: ACCESS.ROLE
    },
    component: () => import('./form/AdminRoleFormPage.vue')
  },
  {
    path: 'role/:uuid',
    name: 'admin.role.detail',
    meta: {
      title: 'Admin Role Detail',
      action: 'update',
      subject: ACCESS.ROLE
    },
    component: () => import('./form/AdminRoleFormPage.vue')
  }
]
