import { createApp } from 'vue'
import { createPinia } from 'pinia'
import * as Sentry from '@sentry/vue'
import VueKonva from 'vue-konva'

import 'element-plus/dist/index.css'
import './styles/tailwind/index.css'
import './styles/index.scss'

import App from './App.vue'
import router from './router'

// Plugins
import { abilitiesPlugin, ability } from './utils/plugins/casl'

const app = createApp(App)
app.use(createPinia())
app.use(router)
app.use(abilitiesPlugin, ability)
app.use(VueKonva)

const isProduction = import.meta.env.NODE_ENV === 'production'

if (import.meta.env.VITE_SENTRY_ENABLE === 'true') {
  const integrations = [
    Sentry.browserTracingIntegration({
      router // Vue Router tracing
    }),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true
    }),
    // Add browser profiling integration to the list of integrations
    Sentry.browserProfilingIntegration()
  ]

  if (import.meta.env.VITE_SENTRY_FEEDBACK_ENABLE === 'true') {
    integrations.push(
      Sentry.feedbackIntegration({
        // Additional SDK configuration goes in here, for example:
        colorScheme: 'system'
      })
    )
  }

  Sentry.init({
    app: app,
    environment: import.meta.env.VITE_NODE_ENV,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations,
    // Track Vue Components
    trackComponents: true,
    // Performance Monitoring
    tracesSampleRate: isProduction ? 0.1 : 1, //
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: isProduction ? 0.1 : 1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

app.mount('#app')
