import { ACCESS } from '@/utils/constants/casl'

export default [
  {
    path: 'outlet-list',
    name: 'outlet.outlet-list',
    meta: {
      title: 'Outlet List',
      action: 'read',
      subject: ACCESS.OUTLET_LIST
    },
    component: () => import('./list/OutletListPage.vue')
  },
  {
    path: 'outlet-list/add',
    name: 'outlet.outlet-list.add',
    meta: {
      title: 'Add Outlet List',
      action: 'create',
      subject: ACCESS.OUTLET_LIST
    },
    component: () => import('./form/OutletListFormPage.vue')
  },
  {
    path: 'outlet-list/:uuid',
    name: 'outlet.outlet-list.detail',
    meta: {
      title: 'Outlet List Detail',
      action: 'read',
      subject: ACCESS.OUTLET_LIST
    },
    component: () => import('./detail/OutletListDetailPage.vue')
  },
  {
    path: 'outlet-list/:uuid/floor-plan',
    name: 'outlet.outlet-list.detail.floor-plan',
    meta: {
      title: 'Floor Plan Outlet',
      action: 'read',
      subject: ACCESS.OUTLET_FLOOR
    },
    component: () => import('./floor-plan/FloorPlanPage.vue'),
    children: [
      {
        path: ':areaUuid',
        action: 'read',
        subject: ACCESS.OUTLET_FLOOR,
        component: () => import('./floor-plan/area/detail/AreaDetailPage.vue'),
        children: [
          {
            path: 'primary',
            name: 'outlet.outlet-list.detail.floor-plan.area.primary',
            action: 'read',
            subject: ACCESS.OUTLET_FLOOR,
            meta: {
              floorType: 'PRIMARY'
            },
            component: () =>
              import(
                '@/pages/outlet/outlet/floor-plan/left-panel/floor-primary/FloorPrimary.vue'
              )
          },
          {
            path: 'temporary',
            action: 'read',
            subject: ACCESS.OUTLET_FLOOR,
            component: () =>
              import(
                '@/pages/outlet/outlet/floor-plan/left-panel/floor-temporary/FloorTemporary.vue'
              ),
            children: [
              {
                path: '',
                name: 'outlet.outlet-list.detail.floor-plan.area.temporary',
                action: 'read',
                subject: ACCESS.OUTLET_FLOOR,
                meta: {
                  floorType: 'TEMPORARY'
                },
                component: () =>
                  import(
                    '@/pages/outlet/outlet/floor-plan/left-panel/floor-temporary/list/FloorTemporaryList.vue'
                  )
              },
              {
                path: ':floorUuid',
                name: 'outlet.outlet-list.detail.floor-plan.area.temporary.detail',
                action: 'read',
                subject: ACCESS.OUTLET_FLOOR,
                meta: {
                  floorType: 'TEMPORARY'
                },
                component: () =>
                  import(
                    '@/pages/outlet/outlet/floor-plan/left-panel/floor-temporary/detail/FloorTemporaryDetail.vue'
                  )
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: 'outlet-list/:uuid/time-management',
    name: 'outlet.outlet-list.detail.time-management',
    meta: {
      title: 'Time Management Outlet',
      action: 'read',
      subject: ACCESS.OUTLET_LIST
    },
    component: () => import('./time-management/TimeManagementPage.vue')
  },
  {
    path: 'outlet-list/:uuid/edit',
    name: 'outlet.outlet-list.edit',
    meta: {
      title: 'Edit Outlet List',
      action: 'update',
      subject: ACCESS.OUTLET_LIST
    },
    component: () => import('./form/OutletListFormPage.vue')
  }
]
