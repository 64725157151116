import { ACCESS } from '@/utils/constants/casl'

export default {
  path: 'brand',
  name: 'brand',
  meta: {
    title: 'Brand',
    action: 'read',
    subject: ACCESS.BRAND
  },
  component: () => import('./BrandPage.vue')
}
