import { ACCESS } from '@/utils/constants/casl'

export default {
  path: 'report',
  name: 'report',
  meta: {
    title: 'Report',
    action: 'read',
    subject: ACCESS.REPORT
  },
  component: () => import('./ReportPage.vue')
}
