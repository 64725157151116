export const ACCESS = {
  PUBLIC: 'public',

  BRAND: 'brand',

  OUTLET_LIST: 'outlet',
  OUTLET_FLOOR: 'outlet-floor',
  OUTLET_ACCESS: 'outlet-access',

  BEHAVIOR_TAGS: 'behaviour-tag',

  GUEST_LIST: 'guest',

  REPORT: 'report',

  ADMIN: 'admin',
  ROLE: 'role'
}
