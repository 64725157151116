import { ACCESS } from '@/utils/constants/casl'

export default {
  path: 'my-profile',
  name: 'my-profile',
  meta: {
    title: 'My Profile',
    action: 'read',
    subject: ACCESS.PUBLIC
  },
  component: () => import('./ProfilePage.vue')
}
