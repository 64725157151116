import { ACCESS } from '@/utils/constants/casl'

export default {
  path: 'guest-list',
  component: () => import('@/components/layouts/ChildLayout.vue'),
  children: [
    {
      path: '',
      name: 'guest-list',
      meta: {
        title: 'Guest List',
        action: 'read',
        subject: ACCESS.GUEST_LIST
      },
      component: () => import('./list/GuestListPage.vue')
    },
    {
      path: ':uuid',
      component: () => import('./detail/GuestListDetailPage.vue'),
      children: [
        {
          path: '',
          redirect: {
            name: 'guest-list.detail.general'
          }
        },
        {
          path: 'general-information',
          name: 'guest-list.detail.general',
          meta: {
            title: 'Guest General Information',
            subject: ACCESS.GUEST_LIST
          },
          component: () => import('./detail/tabs/GeneralInformation.vue')
        },
        {
          path: 'behaviour-tags',
          name: 'guest-list.detail.behaviour-tags',
          meta: {
            title: 'Guest Behaviour Tags',
            subject: ACCESS.GUEST_LIST
          },
          component: () => import('./detail/tabs/BehaviorTags.vue')
        },
        {
          path: 'history-visit',
          name: 'guest-list.detail.history-visit',
          meta: {
            title: 'Guest History Visit',
            subject: ACCESS.GUEST_LIST
          },
          component: () => import('./detail/tabs/HistoryVisit.vue')
        }
      ]
    }
  ]
}
