import { createRouter, createWebHistory } from 'vue-router'

import Login from '@/pages/login/routes'
import Dashboard from '@/pages/dashboard/routes'
import Profile from '@/pages/profile/routes'
import Brand from '@/pages/brand/routes'
import Outlet from '@/pages/outlet/routes'
import Library from '@/pages/library/routes'
import GuestList from '@/pages/guest-list/routes'
import Report from '@/pages/report/routes'
import Admin from '@/pages/admin/routes'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/app',
      component: () => import('@/components/layouts/MainLayout.vue'),
      children: [
        Dashboard,
        Brand,
        Outlet,
        Library,
        GuestList,
        Report,
        Admin,
        Profile
      ]
    },
    {
      path: '/auth',
      component: () => import('@/components/layouts/AuthLayout.vue'),
      children: [Login]
    },
    { path: '/:pathMatch(.*)*', redirect: '/app/dashboard' }
  ]
})

router.beforeEach((to, _from, next) => {
  // Get the page title from the route meta data that we have defined
  // See further down below for how we setup this data
  const title = to.meta.title
  // If the route has a title, set it as the page title of the document/page
  if (title) {
    const parentTitle = ' | CMS Reservation'

    document.title = title + parentTitle
  }
  // Continue resolving the route
  next()
})

export default router
