import { ACCESS } from '@/utils/constants/casl'

export default [
  {
    path: 'behavior-tags',
    name: 'library.behavior-tags',
    meta: {
      title: 'Behaviour Tags List',
      action: 'read',
      subject: ACCESS.BEHAVIOR_TAGS
    },
    component: () => import('./list/BehaviorTagsListPage.vue')
  },
  {
    path: 'behavior-tags/add',
    name: 'library.behavior-tags.add',
    meta: {
      title: 'Behaviour Tags Add',
      action: 'create',
      subject: ACCESS.BEHAVIOR_TAGS
    },
    component: () => import('./form/BehaviorTagsFormPage.vue')
  },
  {
    path: 'behavior-tags/:uuid',
    name: 'library.behavior-tags.detail',
    meta: {
      title: 'Behaviour Tags Detail',
      action: 'read',
      subject: ACCESS.BEHAVIOR_TAGS
    },
    component: () => import('./detail/BehaviorTagsDetailPage.vue')
  },
  {
    path: 'behavior-tags/:uuid/edit',
    name: 'library.behavior-tags.edit',
    meta: {
      title: 'Behaviour Tags Edit',
      action: 'update',
      subject: ACCESS.BEHAVIOR_TAGS
    },
    component: () => import('./form/BehaviorTagsFormPage.vue')
  }
]
