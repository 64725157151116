import { ACCESS } from '@/utils/constants/casl'

export default {
  path: 'outlet-access',
  name: 'outlet.outlet-access',
  meta: {
    title: 'Outlet Access',
    action: 'read',
    subject: ACCESS.OUTLET_ACCESS
  },
  component: () => import('./OutletAccessPage.vue')
}
